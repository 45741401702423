import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content1 from "../sections/nonProf/Content1";
import Hero from "../sections/nonProf/Hero";
import Wnapa from "../sections/nonProf/Wnapa";
import Call from "../sections/nonProf/Call";
import Testimonial from "../sections/nonProf/Testimonial";
const nonProfSol = () => {
  return (
    <>
      <PageWrapper footerDark headerDark>
        <Hero />
        <Content1 />
        <Testimonial />
        <Wnapa />
        <Call />
      </PageWrapper>
    </>
  );
};
export default nonProfSol;
